var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dati_caricati
    ? _c("div", { attrs: { align: "center" } }, [
        _c("br"),
        _vm._m(0),
        _c("br"),
        _c("br"),
        _c("div", { staticClass: "row justify-center" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "Modifica",
                  color: "blue-grey",
                  icon: "mdi-update",
                  size: "xl",
                  disable: _vm.PulsanteModificaDisattivato,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onModificaPreventivo()
                  },
                },
              }),
              _c("QQButton", {
                attrs: {
                  label: _vm.label_pulsante_acquisto,
                  color: "blue-grey",
                  icon: "mdi-newspaper-variant-outline",
                  size: "xl",
                  disable:
                    _vm.PreventivoNonValido() || _vm.is_quotazione_rinnovo,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onRichiestaPratica()
                  },
                },
              }),
              _c("QQButton", {
                attrs: {
                  label: "Registro movimenti",
                  color: "blue-grey",
                  icon: "mdi-grid-off",
                  size: "xl",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onRegistroMovimenti()
                  },
                },
              }),
              _c("QQButton", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.PuoCancellarePreventivo(),
                    expression: "PuoCancellarePreventivo()",
                  },
                ],
                attrs: {
                  label: "Annulla preventivo",
                  color: "blue-grey",
                  icon: "mdi-trash-can-outline",
                  size: "xl",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onCancellaPreventivo()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-5", attrs: { align: "left" } },
            [
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _vm._v("Operatore richiedente:"),
                          ]),
                          _c("q-item-label", {
                            attrs: { caption: "" },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.RecordSelezionato.operatore
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _vm._v("Data emissione Preventivo:"),
                          ]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.RecordSelezionato.data)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Descrizione:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.RecordSelezionato.name)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Preventivo numero:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.RecordSelezionato.numero)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.PreventivoNonValido(),
                          expression: "PreventivoNonValido()",
                        },
                      ],
                    },
                    [
                      _vm.PreventivoScaduto
                        ? _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [_vm._v("ATTENZIONE:")]),
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(
                                  " Il preventivo è stato emesso da piu di 15 giorni e pertanto non può essere modificato o richiederne l'acquisto. In questo ultimo caso è necessario procedere con un nuovo preventivo. "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.is_acquistabile
                        ? _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [_vm._v("ATTENZIONE:")]),
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(
                                  " Il preventivo contiene quotazioni che non risultano ancora lavorate e non può pertanto essere acquistato o modificato. "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h5", [_vm._v("Proposte selezionate")]),
              _c(
                "q-list",
                { attrs: { separator: "", bordered: "" } },
                _vm._l(_vm.proposte_attive, function (proposta, index) {
                  return _c(
                    "q-item",
                    _vm._b({ key: index }, "q-item", proposta, false),
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Compagnia:"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(_vm._s(proposta.compagnia.toUpperCase())),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Tariffa:"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(_vm._s(proposta.tariffa)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        { attrs: { align: "right" } },
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Premio:"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getPremioProposta(proposta)) + " Euro"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("hr"),
        _c("div", { staticClass: "row justify-center" }, [
          _c(
            "div",
            { staticClass: "col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "INDIETRO",
                  color: "blue-grey",
                  icon: "undo",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickIndietro()
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Menu gestione Preventivo"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }